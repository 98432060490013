import Vue from 'vue';
import Vuex from 'vuex';
import * as request from '@/request/api/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //客服链接
        servicerUrl: '',
        //用户余额
        balance: 0,
        //游戏指定参数concise电子/gaming电竞/joker棋牌/lottery彩票/realbet真人/sport体育
        gameTypeList: [
            {
                name: '体育',
                type: 'sport',
                headerShow: 3,
                path: '/game/sport',
                list: [],
                headerList: [],
            },
            {
                name: '真人',
                type: 'realbet',
                headerShow: 6,
                path: '/game/realbet',
                list: [],
                headerList: [],
            },
            {
                name: '棋牌',
                type: 'joker',
                headerShow: 5,
                path: '/game/joker',
                list: [],
                headerList: [],
            },
            {
                name: '电竞',
                type: 'gaming',
                headerShow: 2,
                path: '/game/gaming',
                list: [],
                headerList: [],
            },
            {
                name: '彩票',
                type: 'lottery',
                headerShow: 3,
                path: '/game/lottery',
                list: [],
                headerList: [],
            },
            {
                name: '电子',
                type: 'concise',
                headerShow: 7,
                path: '/game/concise',
                list: [],
                headerList: [],
            },
        ],
        //用户信息
        userInfo:{},
        //系统信息
        systemApp:{},
        //验证码状态
        verifyState:false,
        LOADING: false,
    },
    getters: {
        getUserMobile(state){
            return state.userInfo.mobile;
        },
        getUserEmail(state){
            return state.userInfo.email;
        },
        getSystemApp(state){
            return state.systemApp;
        },
        getVerifyState(state){
            console.log('333');
            return state.verifyState;
        }
    },
    mutations: {
        setVerifyState(stae,bool){
            state.verifyState = bool;
        },
        setServicerUrl(state, url) {
            state.servicerUrl = url;
        },
        setBalance(state, num) {
            state.balance = num;
        },
        setGameTypeList(state, obj) {
            state.gameTypeList.map((item) => {
                if (item.type == obj.type) {
                    item.url = '';
                    item.list = obj.list;
                    item.headerList = obj.list;
                    // item.headerList = obj.list.slice(0, item.headerShow);
                }
            });
        },
        setGameUrl(state, obj) {
            state.gameTypeList.map((item) => {
                item.headerList.map((kItem) => {
                    if (kItem.game_code == obj.game_code) {
                        kItem.url = obj.url;
                    }
                });
            });
        },
        setUserInfo(state, obj){
            state.userInfo = obj;
        },
        setSystemApp(state,obj){
            state.systemApp = obj;
        },
        showLoading(state) {
            state.LOADING = true;
        },
        hideLoading(state) {
            state.LOADING = false;
        },
    },
    actions: {
        getServicerUrl(context) {
            request.default.getServicerUrl().then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    context.commit('setServicerUrl', data.data.url);
                }
            });
        },
        getBalance(context) {
            // console.log(sessionStorage.token);
            // timer && clearInterval(timer);
            // let timer = setInterval(() => {
            //     //定时获取余额
            //     request.default.getBalance().then((res) => {
            //         let data = res.data;
            //         if (data.code === 200) {
            //             context.commit('setBalance', data.data.balance);
            //         }
            //     });
            // }, 10000);
        },
        getGameTypeList(context, category) {
            request.default.getGameList({ category: category }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    context.commit('setGameTypeList', { type: category, list: data.data });
                }
            });
        },
        getUserInfo(context){
            request.default.getUserInfo().then(res=>{
                let data = res.data;
                if(data.code === 200){
                    context.commit('setUserInfo',data.data);
                }
            })
        }
    },
    modules: {},
});
