// 定义全局变量
export default {
    // 公告信息
    api: {
        // 本地开发
        // local_domain:'http://system.lsty023.com',
        // 测试环境
        // test_domain:'http://system.lsty023.com',

        // 正式环境
        // prod_domain:'http://system.lsty023.com',

        local_domain:'http://system.zoty.bet',
        test_domain:'http://system.zoty.bet',
        prod_domain:'http://system.zoty.bet',

    },
    /**
     * http请求，区分是否需要loading
     * @param  {String} url        请求的url
     * @return {Boolean}             true false
     */
    filterUrl: function (url) {
        // let noload = false;
        // let url_list = ['index.php?c=App&a=getAssetsDetail'];
        // // 遍历
        // url_list.map((item) => {
        //     if (item == url) {
        //         noload = true;
        //     }
        // });
        // return noload;
        return true;
    },
    /**
     * http请求地址
     * @param  {String} url        http请求的url
     * @return {String}            请求地址
     */
    baseUrl: function (i) {
        let url = '';
        if (process.env.NODE_ENV === 'development') {
            url = this.api['local_domain' + i];
        } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
            url = this.api['test_domain' + i];
        } else if (process.env.VUE_APP_CURRENTMODE === 'prod') {
            url = this.api['prod_domain' + i];
        }
        return url;
    },
    /**
     * 删除session信息
     * @param {String}          需要保留的字段
     */
    removeSession: function (...item) {
        let obj = new Object();
        item.forEach((_j) => {
            if (sessionStorage.getItem(_j)) {
                obj[_j] = sessionStorage.getItem(_j);
            }
        });
        sessionStorage.clear();
        Object.keys(obj).forEach((key) => {
            sessionStorage.setItem(key, obj[key]);
        });
    },
    /**
     * 图片是否大于15M
     * @param {files}
     */
    filesSizeMax: function (files) {
        let sizeBl = files.size > 1024 * 1024 * 15;
        if (sizeBl) {
            return true;
        }
        return false;
    },
};
