import request from '../http.js';
export default {
    /**
     * 获取客服链接
     * @param data
     * @param isLoad
     */
    getServicerUrl: (data) => {
        return request.Post('/api/getservicerurl', data);
    },
    /**
     * 获取系统信息
     * @param data
     * @param isLoad
     */
    getSystemApp: (data) => {
        return request.Post('/api/app', data);
    },
};
