import request from '../http.js';
export default {
    /**
     * 获取所有游戏
     * @param data
     * @param isLoad
     */
    getGameList: (data) => {
        return request.Get('/api/game/list', data);
    },
    /**
     * 获取电子游戏
     * @param data
     * @param isLoad
     */
    getGameListByCode: (data) => {
        return request.Post('/api/gamelistBycode', data);
    },
    /**
     * 获取游戏地址
     * @param data
     * @param isLoad
     */
    getGameUrl: (data) => {
        return request.Post('/api/getGameUrl', data, {isLoad:'load'});
    },
};
