<template>
    <div class="footer">
        <div class="footer_footer_bg__SILa5">
            <ul>
                <li
                    :class="{ footer_footerScaleBounceAnim__3bq14: item.path == pagePath }"
                    v-for="(item, index) in footerList"
                    :key="index"
                    @click="$router.jumpRoute(item.path)"
                    data-analytics="button"
                    :data-label="item.name"
                >
                    <i></i>
                    <div class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j" style="cursor: inherit">
                        <div style="display: block; overflow: hidden; inset: 0px; box-sizing: border-box; margin: 0px">
                            <img class="footer_icon__1Rh5j footer-img-01" :src="item.imgAct" v-if="item.path == pagePath" />
                            <img class="footer_icon__1Rh5j footer-img-01" :src="item.img" v-else />
                        </div>
                    </div>
                    <span :class="{ footer_active__3TpuY: item.path == pagePath }">{{ item.name }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            footerList: [
                {
                    name: '首页',
                    path: '/',
                    img: require('@/assets/images/static/footer_01.png'),
                    imgAct: require('@/assets/images/static/footer_01_act.png'),
                },
                {
                    name: '优惠',
                    path: '/activity/list',
                    img: require('@/assets/images/static/footer_02.png'),
                    imgAct: require('@/assets/images/static/footer_02_act.png'),
                },
                {
                    name: '客服',
                    path: '/customer/main',
                    img: require('@/assets/images/static/footer_03.png'),
                    imgAct: require('@/assets/images/static/footer_03_act.png'),
                },
                {
                    name: '赞助',
                    path: '/sponsor',
                    img: require('@/assets/images/static/footer_04.png'),
                    imgAct: require('@/assets/images/static/footer_04_act.png'),
                },
                {
                    name: '我的',
                    path: '/mine',
                    img: require('@/assets/images/static/footer_05.png'),
                    imgAct: require('@/assets/images/static/footer_05_act.png'),
                },
            ],
            pagePath: this.$route.path,
        };
    },
    created() {
        //console.log(this.$route.path);
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/static/805d39bc463a48b77c4e.css';
.footer-img-01 {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}
</style>
