// /*
//  *注册公共组件
//  */
//公共底部
import navFooter from '@/components/common/navFooter.vue';
import navHeader from '@/components/common/navHeader.vue';

export default (Vue) => {
    Vue.component('navFooter', navFooter);
    Vue.component('navHeader', navHeader);
};
