import request from '../http.js';
export default {
    /**
     * 登录
     * @param data
     * @param isLoad
     */
    checkLogin: (data) => {
        return request.Post('/api/login_pc', data);
    },
    /**
     * 注册
     * @param data
     * @param isLoad
     */
    register: (data) => {
        return request.Post('/api/register', data);
    },
};
